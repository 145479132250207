import React from "react"
import Link from "gatsby-link"

class GeneralNav extends React.Component {
  render() {
    return (
      <nav
        className="navbar is-pulled-right"
        role="navigation"
        id="navbar-menu-bg"
        aria-label="main navigation"
      >
        <div
          id="navbarBasicExample"
          className="navbar-menu"
          id="navbar-menu-bg "
        >
          <div className="navbar-start">
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">Financial Year Ended</a>

              <div className="navbar-dropdown">
                <a
                  href="/investorcentre/corporateinfo/general/general-2020"
                  className="navbar-item"
                >
                  2020
                </a>
                <a
                  href="/investorcentre/corporateinfo/general"
                  className="navbar-item"
                >
                  2017
                </a>
                <a
                  href="/investorcentre/corporateinfo/general/general-2016"
                  className="navbar-item"
                >
                  2016
                </a>
                <a
                  href="/investorcentre/corporateinfo/general/general-2015"
                  className="navbar-item"
                >
                  2015
                </a>
                <a
                  href="/investorcentre/corporateinfo/general/general-2014"
                  className="navbar-item"
                >
                  2014
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default GeneralNav
